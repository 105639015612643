import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Marquee = () => (
  <div className="marquee">
    <div>
      <section className="feature opacity-0 animate-fade-in hidden lg:flex flex-col justify-center items-center w-[18rem] wh:w-[30rem] absolute [inset-block-start:70%] wh:[inset-block-start:40%] translate-y-[-70%] wh:translate-y-[-40%]">
        <h2 className="mb-[var(--space-2xs)] wh:mb-[var(--space-s)] text-lg uppercase tracking-widest font-normal text-white">New Release</h2>
        {/*<h3 className="mb-[var(--space-2xs)] wh:mb-[var(--space-s)] text-boston-blue text-lg wh:text-xl text-center font-normal font-display mt-0">
          My Piano Album
        </h3>*/}
        <a className="mb-[var(--space-2xs)] wh:mb-[var(--space-s)]" href="https://bfan.link/face-to-the-sun" aria-label="Listen to the album Face to the Sun" rel="nofollow noreferrer">
          <StaticImage
            src="../images/album_Clara-Ponty_My-Piano-Album.jpg"
            width={400}
            alt="Album cover for My Piano Album"
            placeholder="blurred"
          />
        </a>
        <a className="mb-[var(--space-2xs)] wh:mb-[var(--space-s)] max-w-[12rem] inline-block no-underline uppercase tracking-widest border border-2 border-white bg-transparent text-white py-[var(--space-xs)] px-[var(--space-s)] mt-[var(--space-s)]" href="https://bfan.link/my-piano-album" aria-hidden="true" tabIndex="-1" rel="nofollow noreferrer" target="_blank">Listen</a>
      </section>
    </div>
  </div>
);

export default Marquee;
