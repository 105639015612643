import React from 'react';

const AmazonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 103 106.144"
    style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    aria-labelledby="svg-title-1"
  >
    <title id="svg-title-1">Amazon Music</title>
    <g>
      <path fill="currentColor" fillRule="nonzero" d="M90.345 87.377c-43.81,20.85 -70.999,3.406 -88.404,-7.19 -1.077,-0.667 -2.907,0.156 -1.319,1.981 5.798,7.03 24.801,23.976 49.604,23.976 24.821,0 39.587,-13.543 41.434,-15.906 1.835,-2.342 0.539,-3.635 -1.316,-2.861l0.001 0zm12.304 -6.795c-1.177,-1.532 -7.153,-1.817 -10.915,-1.355 -3.768,0.449 -9.424,2.751 -8.931,4.134 0.252,0.518 0.766,0.285 3.355,0.053 2.595,-0.259 9.866,-1.177 11.38,0.803 1.523,1.995 -2.318,11.494 -3.02,13.026 -0.678,1.532 0.259,1.927 1.532,0.907 1.256,-1.02 3.529,-3.662 5.054,-7.4 1.515,-3.758 2.439,-9.001 1.545,-10.168z"/>
      <path fill="currentColor" d="M60.888 44.833c0,5.471 0.138,10.033 -2.627,14.891 -2.232,3.951 -5.768,6.38 -9.718,6.38 -5.392,0 -8.532,-4.108 -8.532,-10.171 0,-11.969 10.724,-14.142 20.877,-14.142l0 3.042zm14.16 34.227c-0.928,0.83 -2.271,0.889 -3.318,0.336 -4.661,-3.871 -5.49,-5.668 -8.058,-9.362 -7.702,7.861 -13.154,10.211 -23.147,10.211 -11.811,0 -21.014,-7.288 -21.014,-21.883 0,-11.396 6.182,-19.158 14.97,-22.95 7.624,-3.357 18.269,-3.95 26.407,-4.878l0 -1.817c0,-3.338 0.256,-7.288 -1.699,-10.172 -1.718,-2.586 -4.997,-3.653 -7.881,-3.653 -5.352,0 -10.132,2.745 -11.297,8.433 -0.237,1.264 -1.165,2.508 -2.429,2.568l-13.628 -1.461c-1.145,-0.258 -2.409,-1.185 -2.093,-2.944 3.14,-16.511 18.051,-21.488 31.402,-21.488 6.834,0 15.761,1.817 21.153,6.992 6.834,6.379 6.182,14.891 6.182,24.154l0 21.883c0,6.577 2.725,9.46 5.293,13.016 0.908,1.264 1.106,2.785 -0.04,3.732 -2.864,2.39 -7.96,6.834 -10.763,9.323l-0.04 -0.04z"/>
      <path fill="currentColor" fillRule="nonzero" d="M90.345 87.377c-43.81,20.85 -70.999,3.406 -88.404,-7.19 -1.077,-0.667 -2.907,0.156 -1.319,1.981 5.798,7.03 24.801,23.976 49.604,23.976 24.821,0 39.587,-13.543 41.434,-15.906 1.835,-2.342 0.539,-3.635 -1.316,-2.861l0.001 0zm12.304 -6.795c-1.177,-1.532 -7.153,-1.817 -10.915,-1.355 -3.768,0.449 -9.424,2.751 -8.931,4.134 0.252,0.518 0.766,0.285 3.355,0.053 2.595,-0.259 9.866,-1.177 11.38,0.803 1.523,1.995 -2.318,11.494 -3.02,13.026 -0.678,1.532 0.259,1.927 1.532,0.907 1.256,-1.02 3.529,-3.662 5.054,-7.4 1.515,-3.758 2.439,-9.001 1.545,-10.168z"/>
      <path fill="currentColor" d="M60.888 44.833c0,5.471 0.138,10.033 -2.627,14.891 -2.232,3.951 -5.768,6.38 -9.718,6.38 -5.392,0 -8.532,-4.108 -8.532,-10.171 0,-11.969 10.724,-14.142 20.877,-14.142l0 3.042zm14.16 34.227c-0.928,0.83 -2.271,0.889 -3.318,0.336 -4.661,-3.871 -5.49,-5.668 -8.058,-9.362 -7.702,7.861 -13.154,10.211 -23.147,10.211 -11.811,0 -21.014,-7.288 -21.014,-21.883 0,-11.396 6.182,-19.158 14.97,-22.95 7.624,-3.357 18.269,-3.95 26.407,-4.878l0 -1.817c0,-3.338 0.256,-7.288 -1.699,-10.172 -1.718,-2.586 -4.997,-3.653 -7.881,-3.653 -5.352,0 -10.132,2.745 -11.297,8.433 -0.237,1.264 -1.165,2.508 -2.429,2.568l-13.628 -1.461c-1.145,-0.258 -2.409,-1.185 -2.093,-2.944 3.14,-16.511 18.051,-21.488 31.402,-21.488 6.834,0 15.761,1.817 21.153,6.992 6.834,6.379 6.182,14.891 6.182,24.154l0 21.883c0,6.577 2.725,9.46 5.293,13.016 0.908,1.264 1.106,2.785 -0.04,3.732 -2.864,2.39 -7.96,6.834 -10.763,9.323l-0.04 -0.04z"/>
    </g>
  </svg>
);

const AppleMusicIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width="32"
    height="32"
    viewBox="0 0 73 73"
    aria-labelledby="svg-title-2"
    style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
  >
    <title id="svg-title-2">Apple Music</title>
    <g>
      <path className="st0" d="M72,19.94c0-0.72-0.01-1.45-0.03-2.17c-0.04-1.58-0.14-3.17-0.42-4.73c-0.28-1.58-0.75-3.06-1.48-4.5
        c-0.72-1.41-1.66-2.71-2.78-3.83c-1.12-1.12-2.42-2.06-3.83-2.78c-1.44-0.73-2.91-1.2-4.49-1.48c-1.56-0.28-3.15-0.37-4.73-0.42
        C53.51,0.02,52.78,0.01,52.06,0c-0.86,0-1.72,0-2.58,0H22.52c-0.86,0-1.72,0-2.58,0c-0.72,0-1.45,0.01-2.17,0.03
        c-1.58,0.04-3.17,0.14-4.73,0.42C11.46,0.74,9.98,1.2,8.55,1.94C7.13,2.66,5.84,3.6,4.72,4.72S2.65,7.13,1.93,8.55
        c-0.73,1.44-1.2,2.91-1.48,4.5c-0.28,1.56-0.37,3.15-0.42,4.73C0.02,18.5,0.01,19.22,0,19.95c0,0.86,0,1.72,0,2.58v26.96
        c0,0.86,0,1.72,0,2.58c0,0.72,0.01,1.45,0.03,2.17c0.04,1.58,0.14,3.17,0.42,4.73c0.28,1.58,0.75,3.06,1.48,4.5
        c0.72,1.41,1.66,2.71,2.78,3.83c1.12,1.12,2.42,2.06,3.83,2.78c1.44,0.73,2.91,1.2,4.49,1.48c1.56,0.28,3.15,0.37,4.73,0.42
        c0.72,0.02,1.45,0.03,2.17,0.03c0.86,0.01,1.72,0,2.58,0h26.95c0.86,0,1.72,0,2.58,0c0.72,0,1.45-0.01,2.17-0.03
        c1.58-0.04,3.17-0.14,4.73-0.42c1.58-0.28,3.06-0.75,4.49-1.48c1.41-0.72,2.71-1.66,3.83-2.78s2.06-2.41,2.78-3.83
        c0.73-1.44,1.2-2.91,1.48-4.5c0.28-1.56,0.37-3.15,0.42-4.73c0.02-0.72,0.03-1.45,0.03-2.17c0.01-0.86,0-1.72,0-2.58V22.52
        C72,21.66,72,20.8,72,19.94z M52.71,46.85c0,0.91-0.01,1.74-0.2,2.65c-0.19,0.89-0.53,1.72-1.05,2.47
        c-0.52,0.75-1.19,1.36-1.97,1.82c-0.79,0.47-1.62,0.73-2.5,0.91c-1.66,0.33-2.79,0.41-3.86,0.2c-1.03-0.21-1.9-0.68-2.6-1.32
        c-1.03-0.95-1.68-2.23-1.82-3.56c-0.16-1.57,0.36-3.24,1.53-4.48c0.59-0.62,1.34-1.11,2.34-1.5c1.04-0.4,2.19-0.65,3.96-1
        c0.47-0.09,0.93-0.19,1.4-0.28c0.61-0.12,1.14-0.28,1.56-0.8c0.43-0.52,0.43-1.16,0.43-1.78V24.32c0-1.21-0.54-1.54-1.7-1.32
        c-0.83,0.16-18.62,3.75-18.62,3.75c-1,0.24-1.36,0.57-1.36,1.82v23.23c0,0.91-0.05,1.74-0.24,2.65c-0.19,0.89-0.53,1.72-1.05,2.47
        c-0.52,0.75-1.19,1.36-1.97,1.82c-0.79,0.47-1.62,0.74-2.5,0.92c-1.66,0.33-2.79,0.41-3.86,0.2c-1.03-0.21-1.9-0.69-2.6-1.33
        c-1.03-0.95-1.63-2.23-1.78-3.56c-0.16-1.57,0.31-3.24,1.49-4.48c0.59-0.62,1.34-1.11,2.34-1.5c1.04-0.4,2.19-0.65,3.96-1
        c0.47-0.09,0.93-0.19,1.4-0.28c0.61-0.12,1.14-0.28,1.56-0.8c0.42-0.52,0.47-1.13,0.47-1.75c0-4.92,0-26.79,0-26.79
        c0-0.36,0.03-0.6,0.05-0.72c0.09-0.56,0.31-1.05,0.72-1.39c0.34-0.28,0.78-0.48,1.33-0.6l0.01,0L49,11.33
        c0.19-0.04,1.73-0.31,1.91-0.33c1.16-0.1,1.81,0.66,1.81,1.89L52.71,46.85L52.71,46.85z" />
    </g>
  </svg>
);

const DeezerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    viewBox="0 0 343 343"
    aria-labelledby="svg-title-3"
  >
    <title id="svg-title-3">Deezer</title>
    <g>
      <rect fill="none" width="32" height="32"/>
      <g>
        <polygon fill="currentColor" points="97.155,218.399 164.174,218.399 164.41,179.103 96.041,179.103 "/>
        <polygon fill="currentColor" points="97.143,163.697 162.957,163.697 164.41,163.697 164.396,124.401 97.259,124.401 "/>
        <polygon fill="currentColor" points="97.159,273.13 164.174,273.13 164.414,233.838 97.259,233.837 "/>
        <polygon fill="currentColor" points="178.557,273.13 245.689,273.13 245.806,233.838 178.774,233.837 "/>
        <polygon fill="currentColor" points="178.551,218.399 245.385,218.399 245.802,179.103 178.994,179.103 "/>
        <polygon fill="currentColor" points="327.202,272.382 327.138,233.837 261.499,234.014 261.113,272.382 "/>
        <polygon fill="currentColor" points="261.499,108.786 326.816,108.968 327.202,70.418 261.082,70.418 "/>
        <polygon fill="currentColor" points="15.745,272.382 81.822,272.382 81.448,234.014 16.132,233.832 "/>
        <polygon fill="currentColor" points="327.217,217.679 327.255,179.748 261.113,179.748 261.049,217.679 "/>
        <polygon fill="currentColor" points="327.255,163.052 327.217,125.121 261.113,125.121 261.201,163.052 "/>
        <path fill="currentColor" d="M327.217 217.679l-66.168 0 0.064 -37.931 66.142 0c-4.154,-2.45 -64.572,-1.237 -67.433,-0.298l-0.206 38.617c2.64,0.802 54.581,1.946 63.993,0.86 5.632,-0.65 1.823,-0.139 3.608,-1.248z"/>
        <path fill="currentColor" d="M327.255 163.052l-66.054 0 -0.088 -37.931 66.104 0c-4.183,-2.599 -64.964,-1.144 -67.363,-0.389l-0.245 38.618c6.026,1.916 39.642,0.853 50.562,0.853l17.084 -1.151z"/>
        <path fill="currentColor" d="M327.202 272.382l-66.089 0 0.386 -38.368c-3.423,1.104 -1.908,11.049 -1.908,18.901 0,6.449 -0.402,13.556 0.271,19.894 2.4,0.739 54.511,1.922 63.745,0.839 6.247,-0.733 1.285,0.198 3.595,-1.266z"/>
        <path fill="currentColor" d="M15.745 272.382c4.168,2.64 65.008,1.145 67.341,0.427 0.654,-6.353 0.263,-13.435 0.263,-19.894l0.125 -14.702c-0.607,-6.264 0.372,-2.67 -2.026,-4.199l0.374 38.368 -66.077 0z"/>
        <path fill="currentColor" d="M261.499 108.786l-0.417 -38.368 66.12 0c-4.168,-2.641 -65.008,-1.145 -67.34,-0.427l-0.396 34.596c0.61,6.254 -0.363,2.676 2.033,4.199z"/>
      </g>
    </g>
  </svg>
);

const FacebookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width="32"
    height="32"
    style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    aria-labelledby="svg-title-4"
  >
    <title id="svg-title-4">Facebook</title>
    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
  </svg>
);

const QobuzIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 343 343"
    style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    aria-labelledby="svg-title-5"
  >
    <title id="svg-title-5">Qobuz</title>
    <g>
      <rect fill="none" width="32" height="32"/>
      <path fill="currentColor" d="M171.026 55.945c62.555,0 113.265,50.71 113.265,113.265 0,27.939 -10.121,53.51 -26.89,73.261l-25.061 -25.062c-4.701,-4.7 -12.394,-4.7 -17.095,0l-0.004 0.005c-4.701,4.701 -4.701,12.393 0,17.094l24.635 24.636c-0.175,0.134 -0.352,0.268 -0.529,0.401l0.931 0 20.729 20.729 7.537 7.516 -0.012 0.01 22.519 22.519 17.452 -17.451 -31.173 -31.173 0.051 -0.046 -0.004 -0.004c21.523,-24.741 34.554,-57.067 34.554,-92.435 0,-77.82 -63.085,-140.905 -140.905,-140.905 -77.82,0 -140.905,63.085 -140.905,140.905 0,77.82 63.085,140.905 140.905,140.905 29.177,0 56.283,-8.87 78.769,-24.058l-20.007 -20.006c-17.131,10.42 -37.244,16.424 -58.762,16.424 -62.555,0 -113.265,-50.71 -113.265,-113.265 0,-62.555 50.71,-113.265 113.265,-113.265zm122.872 205.322l31.6 31.601 -34.447 34.446 -32.181 -32.182c-24.89,17.395 -55.175,27.602 -87.844,27.602 -84.789,0 -153.524,-68.735 -153.524,-153.524 0,-84.789 68.735,-153.524 153.524,-153.524 84.789,0 153.524,68.735 153.524,153.524 0,34.536 -11.405,66.408 -30.652,92.057zm-122.872 -102.572c5.808,0 10.515,4.707 10.515,10.515 0,5.808 -4.707,10.515 -10.515,10.515 -5.808,0 -10.515,-4.707 -10.515,-10.515 0,-5.808 4.707,-10.515 10.515,-10.515zm0 -33.965c24.566,0 44.48,19.914 44.48,44.48 0,24.566 -19.914,44.481 -44.48,44.481 -24.566,0 -44.481,-19.915 -44.481,-44.481 0,-24.566 19.915,-44.48 44.481,-44.48z"/>
    </g>
  </svg>
);

const SpotifyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width="32"
    height="32"
    viewBox="0 0 130.262 130.262"
    aria-labelledby="svg-title-6"
    style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
  >
    <title id="svg-title-6">Spotify</title>
    <g>
      <path d="M65.131 0c-35.971,0 -65.131,29.16 -65.131,65.131 0,35.973 29.16,65.131 65.131,65.131 35.973,0 65.131,-29.158 65.131,-65.131 0,-35.969 -29.158,-65.128 -65.131,-65.128l0 -0.003zm29.867 93.938c-1.165,1.914 -3.67,2.521 -5.583,1.346 -15.292,-9.341 -34.543,-11.456 -57.215,-6.278 -2.184,0.499 -4.363,-0.869 -4.858,-3.055 -0.501,-2.187 0.861,-4.365 3.053,-4.861 24.808,-5.669 46.089,-3.227 63.257,7.264 1.913,1.174 2.521,3.671 1.346,5.584zm7.972 -17.735c-1.468,2.391 -4.595,3.143 -6.983,1.675 -17.507,-10.762 -44.195,-13.879 -64.901,-7.592 -2.687,0.811 -5.522,-0.703 -6.338,-3.385 -0.809,-2.685 0.707,-5.515 3.387,-6.331 23.652,-7.18 53.059,-3.703 73.163,8.653 2.388,1.47 3.143,4.598 1.672,6.98zm0.684 -18.466c-20.989,-12.469 -55.622,-13.614 -75.665,-7.532 -3.217,0.976 -6.62,-0.84 -7.595,-4.06 -0.976,-3.22 0.839,-6.62 4.059,-7.598 23.006,-6.986 61.251,-5.637 85.418,8.711 2.902,1.718 3.851,5.457 2.134,8.348 -1.713,2.894 -5.462,3.848 -8.346,2.131l-0.005 0z"/>
    </g>
  </svg>
);

const TwitterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width="32"
    height="32"
    aria-labelledby="svg-title-7"
  >
    <title id="svg-title-7">Twitter</title>
    <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
  </svg>
);

const YouTubeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width="32"
    height="32"
    aria-labelledby="svg-title-8"
  >
    <title id="svg-title-8">YouTube</title>
    <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
  </svg>
);

const YouTubeMusicIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 32 32"
    width="32"
    height="32"
    aria-labelledby="svg-title-9"
    style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
  >
    <title id="svg-title-9">YouTube Music</title>
    <g>
      <path fill="currentColor" d="M6.563 16.375c0,-1.705 0.167,-2.974 0.874,-4.375 0.624,-1.236 1.394,-2.334 2.456,-3.232 1.457,-1.232 3.697,-2.205 5.732,-2.205 1.694,0 2.985,0.172 4.375,0.874 0.505,0.255 0.99,0.491 1.429,0.821 1.483,1.115 2.261,2.011 3.113,3.7 1.903,3.776 0.721,8.604 -2.435,11.274 -0.637,0.539 -1.306,0.927 -2.065,1.31 -3.776,1.903 -8.604,0.721 -11.274,-2.435 -1.232,-1.457 -2.205,-3.697 -2.205,-5.732zm8.91 -16.375l1.053 0c2.178,0.068 4.336,0.561 6.246,1.478 0.871,0.419 1.701,0.88 2.452,1.423 2.387,1.728 4.044,3.716 5.298,6.327 1.135,2.364 1.478,4.455 1.478,7.335 0,2.201 -0.77,4.77 -1.499,6.25 -0.808,1.641 -2.352,3.965 -3.725,5.026l-1.053 0.884c-2.531,1.891 -5.768,3.277 -9.223,3.277 -3.624,0 -5.745,-0.593 -8.582,-2.168 -1.986,-1.102 -4.271,-3.294 -5.415,-5.21 -1.822,-3.051 -2.503,-5.196 -2.503,-9.185 0,-5.005 3.181,-10.428 7.378,-12.934l1.85 -1.025c1.912,-0.917 4.07,-1.41 6.245,-1.478z"/>
      <path fill="currentColor" d="M12.937 20.687l0 -9.374c0.333,0.175 0.656,0.376 0.988,0.574 0.343,0.206 0.646,0.362 0.993,0.57l5.032 2.906c0.211,0.127 0.856,0.523 1.05,0.575l0 0.124c-0.232,0.062 -1.64,0.931 -2,1.126 -0.685,0.37 -1.319,0.782 -2.021,1.166 -0.351,0.191 -0.654,0.369 -0.991,0.571l-2.024 1.164c-0.352,0.208 -0.688,0.419 -1.027,0.598zm-5.25 -4.937c0,0.9 0.017,1.42 0.237,2.263 0.152,0.584 0.315,0.97 0.522,1.478 0.193,0.476 0.818,1.451 1.164,1.837 0.092,0.102 0.156,0.145 0.247,0.253 0.113,0.135 0.119,0.174 0.25,0.312 0.481,0.502 1.713,1.377 2.356,1.644 2.558,1.062 4.514,1.039 7.028,0.017 0.698,-0.284 1.855,-1.115 2.401,-1.662 1.414,-1.413 2.421,-3.607 2.421,-5.642 0,-2.023 -0.571,-4.073 -1.923,-5.577 -0.101,-0.113 -0.138,-0.132 -0.239,-0.262 -0.112,-0.145 -0.126,-0.177 -0.258,-0.304l-0.533 -0.467c-1.765,-1.661 -4.918,-2.356 -7.373,-1.716 -0.761,0.198 -1.481,0.479 -2.132,0.869 -0.226,0.135 -0.415,0.236 -0.615,0.385 -0.184,0.136 -0.4,0.281 -0.568,0.432 -0.102,0.092 -0.145,0.156 -0.253,0.247 -0.758,0.637 -1.582,1.7 -1.955,2.607 -0.4,0.97 -0.777,1.95 -0.777,3.286z"/>
    </g>
  </svg>
);

export {
  AmazonIcon,
  AppleMusicIcon,
  DeezerIcon,
  FacebookIcon,
  QobuzIcon,
  SpotifyIcon,
  TwitterIcon,
  YouTubeIcon,
  YouTubeMusicIcon,
};

