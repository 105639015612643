import React from 'react';

import {
  AmazonIcon,
  AppleMusicIcon,
  DeezerIcon,
  FacebookIcon,
  QobuzIcon,
  SpotifyIcon,
  TwitterIcon,
  YouTubeIcon,
} from './socialIcons';

const Social = () => (
  <ul className="social cluster mt-0">
    <li>
      <a href="https://music.apple.com/ca/artist/clara-ponty/19062855" className="block" rel="nofollow noreferrer" target="_blank">
        <AppleMusicIcon />
      </a>
    </li>
    <li>
      <a href="https://open.spotify.com/artist/6DlfpltePGjtO57AncSofZ" className="block" rel="nofollow noreferrer" target="_blank">
        <SpotifyIcon />
      </a>
    </li>
    <li>
      <a href="https://www.deezer.com/en/artist/183654" className="block" rel="nofollow noreferrer" target="_blank">
        <DeezerIcon />
      </a>
    </li>
    <li>
      <a href="https://www.qobuz.com/fr-fr/interpreter/clara-ponty/download-streaming-albums" className="block" rel="nofollow noreferrer" target="_blank">
        <QobuzIcon />
      </a>
    </li>
    <li>
      <a href="https://www.youtube.com/channel/UCW7OoKMJ_55BZV-2Lj6FS0Q" className="block" rel="nofollow noreferrer" target="_blank">
        <YouTubeIcon />
      </a>
    </li>
    <li>
      <a href="https://www.amazon.fr/Clara-Ponty/e/B000API7NC/digital/ref=ntt_mp3_rdr?_encoding=UTF8&sn=d" className="block" rel="nofollow noreferrer" target="_blank">
        <AmazonIcon />
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/ClaraPonty" className="block" rel="nofollow noreferrer" target="_blank">
        <FacebookIcon />
      </a>
    </li>
    <li>
      <a href="https://twitter.com/ClaraPonty" className="block" rel="nofollow noreferrer" target="_blank">
        <TwitterIcon />
      </a>
    </li>
  </ul>
);

export default Social;
